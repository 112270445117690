<template>
  <div>
    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title class="headline red white--text" primary-title>
          <v-spacer />Atenção
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-5">Deseja sair, todo o processo será encerrado</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn text @click="openDialog = false">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn text to="/pages/news">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Snackbar
    ============================================================-->
   <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false">Cancelar</v-btn>
      </template>
    </v-snackbar>
    <!-- =======================================================
                                Main
    ============================================================-->
    <v-container id="createEditGallery" fluid tag="section">
      <v-row justify="center">
        <v-col cols="12">
          <v-form>
            <v-container>
              <v-row justify="center">
                <v-col cols="4">
                  <v-text-field
                    v-model="title"
                    label="Titulo da galeria"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    v-model="category"
                    outlined
                    :items="categoryes"
                    :rules="[v => !!v || 'Escolha uma categoria']"
                    label="Categoria"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="4">
                              <v-menu
                                v-model="menuPicker"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="computedDateFormatted"
                                    label="Data da postagem"
                                    persistent-hint
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @blur="date = parseDate(dateFormatted)"
                                    outlined
                                    required
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="date"
                                  locale="pt-br"
                                  no-title
                                  @input="menuPicker = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
              </v-row>
              <v-row justify="center">
                <v-col class="d-flex align-center justify-space-between" cols="12">
                  <input
                    v-show="false"
                    accept="image/*"
                    ref="inputUpload"
                    type="file"
                    multiple
                    @change="onFileChange"
                  />
                  <v-btn color="success" @click="$refs.inputUpload.click()">Adicionar Imagens</v-btn>

                  <v-btn color="primary" @click="create()">Salvar</v-btn>
                </v-col>
                <v-col cols="12 pa-5">
                  <v-divider></v-divider>
                  <v-row wrap justify="center" class="pa-5">

                    <h4 v-if="imagesGaleryPreview.length === 0">Nenhuma imagem encontrada.</h4>
                    <v-card
                      class="ma-5 card-img"
                      v-for="(file,index) in imagesGaleryPreview"
                      :key="index"
                    >
                      <v-img :src="file.img" class="white--text align-end" height="200px" contain></v-img>
                      <v-card-actions class="card-actions">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="green"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="alterMain(index)"
                            >{{file.ind_main ?'mdi-checkbox-marked':'mdi-checkbox-blank-outline'}}</v-icon>
                          </template>
                          <span>{{file.ind_main ?'Marcado':'Marcar'}}</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="red"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteImg(index)"
                            >mdi-delete</v-icon>
                          </template>
                          <span>Excluir</span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import categoryService from "../../../../service/categoryService";
import galleryService from "../../../../service/galleryService";

export default {
  name: "CreateEditGallery",
  components: {
    Card: () => import("../../../../components/commons/Card"),
  },
  data: vm => ({
    title: "",
    category: null,
    imagesGalery: [],
    imagesGaleryPreview: [],
    imagesGaleryRemove: [],
    menuPicker: false,
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    overlay: false,
    openDialog: false,
    categoryes:[],
    snackbar: {
      text: "",
      active: false
    },
  }),
  methods: {
     formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
    },
    fileUrl(url) {
      this.fileImgCardPreview = url;
    },
    cancel() {
      this.title || this.category || this.date
        ? (this.openDialog = true)
        : "";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const filesConvert = Object.keys(files).forEach(file => {
        this.getBase64(files[file]).then(get => {
            this.imagesGaleryPreview.push({
              ind_main: this.imagesGaleryPreview.filter(res => res.ind_main).length > 0 ?false:true,
              img: get,
              file: files[file]
            });
          return get;
        });
      });
    },
    alterMain(indexImg) {
      this.imagesGaleryPreview = this.imagesGaleryPreview.map((img, index) => {
        if (indexImg === index) {
          img.ind_main = true;
        } else {
          img.ind_main = false;
        }

        return img;
      });
    },
    deleteImg(index) {
      
      const ind_main = this.imagesGaleryPreview.length > 0 && this.imagesGaleryPreview[index].ind_main

      if(this.imagesGaleryPreview[index] && this.imagesGaleryPreview[index].id_gallery_content){
        this.imagesGaleryRemove.push(this.imagesGaleryPreview[index].id_gallery_content)
      }
      this.imagesGaleryPreview.splice(index, 1);

       if(ind_main){
        this.alterMain(0)
      }
    },
    getCategorys() {
      categoryService.get({}).then(res => {
        this.categoryes = res.data.map(category => {
          
          
          category.value = category.id;
          category.text = category.name;
          return category;
        });
      });
    },
    async create() {

      const formData = new FormData();

      if(this.validateData()){

      const indeximage = this.imagesGaleryPreview.filter((image,index) => {
          return typeof image.file === 'object'
      }).findIndex(image => {
        return image.ind_main
      })

      this.imagesGaleryPreview.forEach((image, index) => {

          if(typeof image.file === 'object'){
            formData.append(`images`,image.file,`gallery_${new Date().getTime()}`);
            image.ind_main ? formData.append("index_ind_main", indeximage) : null;
          }else{
            image.ind_main ? formData.append("id_gallery_content", image.id_gallery_content) : null;
          }
      });

      formData.append("title", this.title);
      formData.append("id_category", this.category);
      formData.append("date", this.formatDate(this.date));

      if (this.id) {

        if(this.imagesGaleryRemove.length > 0){
          formData.append("imagesGaleryRemove",JSON.stringify(this.imagesGaleryRemove));  
        }
        
        this.overlay = true;
        galleryService
          .update(this.id, formData)
          .then(res => {
            setTimeout(()=>{
              this.overlay = false;
              this.$router.push({ path: "/pages/gallery" });
            },2000)
          })
          .catch(error => {
            
            this.snackbar.text = "Erro ao salvar a galeria.";
            this.snackbar.active = true;
          });
      } else {
        this.overlay = true;
        galleryService
          .create(formData)
          .then(res => {
            setTimeout(()=>{
              this.overlay = false;
              this.$router.push({ path: "/pages/gallery" });
            },2000)
          })
          .catch(error => {
            this.overlay = false;
            this.snackbar.text = "Erro ao salvar a galeria.";
            this.snackbar.active = true;
          });
          }
      }
    },
    validateData() {
      let result = true
      if (!this.title) {
        this.snackbar.text = "O titulo não pode ser vazio!";
        this.snackbar.active = true;
        result =  false
      } else if (!this.category) {
        this.snackbar.text = "A categoria não pode ser vazia!";
        this.snackbar.active = true;
        result =  false
      } else if (this.imagesGaleryPreview.length === 0) {
        this.snackbar.text = "A galeria deve conter pelo menos uma imagem.";
        this.snackbar.active = true;
        result =  false
      } 

      return result
      
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => reject(error);
      });
    }
  },
  computed:{
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  mounted() {
    this.id = parseInt(this.$router.history.current.params.id);
    this.getCategorys();

    if (this.id) {
      this.overlay = true;
      galleryService.get({id:this.id}).then(res => {
        const data = res.data;
        this.title = data[0].title;
        this.category = data[0].id_category;
        this.date = this.parseDate(data[0].date)
        this.imagesGaleryPreview = data.map(file => {
          return {
            img:file.path, 
            ind_main:file.ind_main, 
            id_gallery_content:file.id_gallery_content
            }
        })
        this.overlay = false;
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.card-img
  width: 300px
  background-color: #5a5a5a
  // border-radius:0 !important 
  border: 2px solid #e8e8e8
  box-shadow: 0px 0px 5px 0px rgb(0, 0, 0)

.card-actions
  background-color: #e8e8e8
</style>